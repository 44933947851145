import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import ContentHelper from 'better_consult/components/ContentHelper.js';
import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    productName: {
        id: 'accounts.AccountsPrompt.productName',
        defaultMessage: 'productName',
    },
});

export class AccountsPrompt extends React.Component {
    static propTypes = {
        brandColour: PropTypes.string,
        helperContent: PropTypes.shape({
            content: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]),
            label: PropTypes.string,
            title: PropTypes.string,
        }),
        helperOpenByDefault: PropTypes.bool,
        logoAlt: PropTypes.string,
        logoUrl: PropTypes.string,
    };

    static defaultProps = {
        logoAlt: '',
        helperOpenByDefault: false,
    };

    render() {
        const intl = this.props.intl;
        return (
            <React.Fragment>
                {this.props.logoUrl ? (
                    <img alt={this.props.logoAlt} src={this.props.logoUrl} />
                ) : (
                    <React.Fragment>
                        <Icon name="IconLogo" />
                        <h1>{intl.formatMessage(messages.productName)}</h1>
                    </React.Fragment>
                )}
                <header
                    className="title"
                    style={{
                        '--brandColor': this.props.brandColour,
                    }}
                >
                    {this.props.children}
                </header>
                {this.props.helperContent && (
                    <ContentHelper
                        helperContent={this.props.helperContent}
                        openByDefault={this.props.helperOpenByDefault}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default injectIntl(AccountsPrompt);
