import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {logoutUser} from 'accounts/actions/accountsActions.js';
import MenuBar from 'core/components/MenuBar.js';
import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    loggedOutHeader: {
        id: 'accounts.Logout.header',
        defaultMessage: 'header',
    },
    success: {
        id: 'accounts.Logout.success',
        defaultMessage: 'success',
    },
    thankYou: {
        id: 'accounts.Logout.thankYou',
        defaultMessage: 'thankYou',
    },
});

export class Logout extends React.Component {
    componentDidMount() {
        if (this.props.loggedIn) {
            this.props.logoutUser();
        }
    }

    render() {
        return (
            <section className="view-status">
                <MenuBar menuType="accounts" />
                <figure>
                    <Icon name="IconLoggedOut" />
                </figure>
                <header>
                    <h1 data-test-id="logout-title">
                        {this.props.intl.formatMessage(
                            messages.loggedOutHeader,
                        )}
                    </h1>
                    <p data-test-id="logout-subtitle">
                        {this.props.intl.formatMessage(messages.thankYou)}
                    </p>
                </header>
            </section>
        );
    }
}

export function mapStateToProps(store) {
    return {loggedIn: store.accounts.user.loggedIn};
}

function mapDispatchToProps(dispatch) {
    return {
        logoutUser: () => dispatch(logoutUser()),
    };
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(Logout),
);
