import React from 'react';

export default class NoMatch extends React.Component {
    componentDidMount() {
        location = '/404/';
    }

    render() {
        return <h1>Redirecting...</h1>;
    }
}
