import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import AccountsPrompt from 'accounts/components/AccountsPrompt.js';
import {setLanguage} from 'accounts/actions/accountsActions.js';
import {ZONE} from 'accounts/helpers.js';
import MenuBar from 'core/components/MenuBar.js';

export const messages = defineMessages({
    buttonMessage: {
        id: 'accounts.ConsultationSuccess.buttonMessage',
        defaultMessage: 'accounts.ConsultationSuccess.buttonMessage',
    },
    walkInSuccess2: {
        id: 'accounts.ConsultationSuccess.walkInSuccess2',
        defaultMessage: 'walkInSuccess2',
    },
    success: {
        id: 'accounts.ConsultationSuccess.success',
        defaultMessage: 'success',
    },
    success2: {
        id: 'accounts.ConsultationSuccess.success2',
        defaultMessage: 'success2',
    },
    thankYou: {
        id: 'accounts.ConsultationSuccess.thankYou',
        defaultMessage: 'thankYou',
    },
    tooManyIssues: {
        id: 'accounts.ConsultationSuccess.tooManyIssues',
        defaultMessage: 'tooManyIssues',
    },
});

export class ConsultationSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.zone = document.body.dataset.zone;
    }

    componentDidMount() {
        if (this.props.walkIn) {
            this.props.setLanguage(null);
            this.timer = setTimeout(this.redirect, 30000);
            this.disableUndoStep();
        }

        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
    }

    disableUndoStep() {
        window.onpopstate = () => {
            history.pushState(
                null,
                null,
                '/consultations/consultation-success/',
            );
        };
    }

    renderConsultationSuccessMessage() {
        const intl = this.props.intl;
        const tooManyIssues = this.props.numberOfIssues > 2;
        let tooManyIssuesMessage = '';
        if (tooManyIssues) {
            tooManyIssuesMessage = intl.formatMessage(messages.tooManyIssues, {
                num: this.props.numberOfIssues,
            });
        }
        return this.props.walkIn ? (
            <>
                <strong>{intl.formatMessage(messages.thankYou)}</strong>
                <p>
                    {intl.formatMessage(messages.success)}{' '}
                    {this.zone === ZONE.AU &&
                        intl.formatMessage(messages.walkInSuccess2)}
                </p>
            </>
        ) : (
            <>
                <strong>{intl.formatMessage(messages.thankYou)}</strong>
                <p>
                    {intl.formatMessage(messages.success)}{' '}
                    {intl.formatMessage(messages.success2)}
                </p>
                {tooManyIssues && <p>{tooManyIssuesMessage}</p>}
            </>
        );
    }

    redirect() {
        window.location.assign('/consultations/new/');
    }

    render() {
        const menuType = this.props.walkIn
            ? 'consultation'
            : 'consultationSuccess';
        return (
            <section className="view">
                <MenuBar menuType={menuType} />
                <div
                    className="prompt success"
                    data-test-id="consultation-success-message"
                >
                    <AccountsPrompt>
                        {this.renderConsultationSuccessMessage()}
                    </AccountsPrompt>
                    {this.props.walkIn && this.zone === ZONE.AU && (
                        <button
                            className="cta"
                            data-test-id="consultation-success-redirect-button"
                            onClick={this.redirect}
                            type="button"
                        >
                            {this.props.intl.formatMessage(
                                messages.buttonMessage,
                            )}
                        </button>
                    )}
                </div>
            </section>
        );
    }
}

function getNumberOfIssues(store) {
    const numberOfPresentingConditions = Object.values(
        store.data.consultations.pastConditions,
    ).filter((pastCondition) => pastCondition.isPresentingProblem).length;
    const numberOfSymptoms = Object.values(
        store.data.consultations.consultation.consultationSymptoms,
    ).length;
    return numberOfSymptoms + numberOfPresentingConditions;
}

export function mapStateToProps(store) {
    return {
        numberOfIssues: getNumberOfIssues(store),
        walkIn: store.accounts.user.isWalkInUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setLanguage: (language) => dispatch(setLanguage(language)),
    };
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ConsultationSuccess),
);
