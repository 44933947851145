import autobind from 'common/decorators/autobind.js';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import {ACCOUNTS_RESEND_EMAIL_CONFIRMATION} from 'apiEndpoints.js';
import FormNotice from 'core/components/FormNotice.js';
import http from 'http.js';
import MenuBar from 'core/components/MenuBar.js';
import query from 'query.js';
import SingleInput from 'core/components/SingleInput.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

const messages = defineMessages({
    emailLabel: {
        id: 'accounts.ResendConfirmEmail.emailLabel',
        defaultMessage: 'emailLabel',
    },
    button: {
        id: 'accounts.ResendConfirmEmail.button',
        defaultMessage: 'button',
    },
    title: {
        id: 'accounts.ResendConfirmEmail.title',
        defaultMessage: 'title',
    },
    subTitle: {
        id: 'accounts.ResendConfirmEmail.subTitle',
        defaultMessage: 'subTitle',
    },
    successMessage: {
        id: 'accounts.ResendConfirmEmail.successMessage',
        defaultMessage: 'successMessage',
    },
    emailSupport: {
        id: 'accounts.ResendConfirmEmail.emailSupport',
        defaultMessage: 'emailSupport',
    },
    errorMessage: {
        id: 'accounts.ResendConfirmEmail.errorMessage',
        defaultMessage: 'errorMessage',
    },
});

export class ResendConfirmEmail extends React.Component {
    constructor(props) {
        super(props);

        const queryData = snakeToCamelObjectKeys(query.parse());
        this.state = {
            confirmationKey: queryData.confirmationKey,
            email: queryData.email,
            token: queryData.token,
            success: false,
            error: null,
        };
    }

    @autobind
    updateEmail(email) {
        this.setState({email});
    }

    @autobind
    async confirmEmail(event) {
        event.preventDefault();
        try {
            const data = {
                'confirmation_key': this.state.confirmationKey,
                'email': this.state.email,
                'token': this.state.token,
            };
            await http.post({
                url: ACCOUNTS_RESEND_EMAIL_CONFIRMATION,
                data,
            });
            this.setState({
                error: null,
                success: true,
            });
        } catch (err) {
            let errorMsg = this.props.intl.formatMessage(
                messages.errorMessage,
            );
            const message = JSON.parse(err.message);
            if (message.contentType === 'application/json') {
                const response = JSON.parse(message.response);
                errorMsg = Object.values(response).join(', ');
            }
            this.setState({
                error: errorMsg,
                success: false,
            });
        }
    }

    render() {
        const intl = this.props.intl;
        return (
            <section className="view">
                <MenuBar menuType="accounts" />
                <form onSubmit={this.confirmEmail}>
                    <header className="title">
                        <h1 data-test-id="resend-confirm-email-title">
                            {intl.formatMessage(messages.title)}
                        </h1>
                        <p data-test-id="resend-confirm-email-subtitle">
                            {intl.formatMessage(messages.subTitle)}
                        </p>
                    </header>
                    {this.state.success && (
                        <FormNotice
                            errorType="success"
                            message={intl.formatMessage(
                                messages.successMessage,
                            )}
                        />
                    )}
                    {this.state.error && (
                        <FormNotice
                            errorType="error"
                            message={this.state.error}
                        />
                    )}
                    <SingleInput
                        autoFocus={true}
                        defaultValue={this.state.email}
                        id="email"
                        label={intl.formatMessage(messages.emailLabel)}
                        name="email"
                        onChange={this.updateEmail}
                        readOnly={this.state.success}
                        required={true}
                        type="email"
                    />
                    <button
                        className="cta"
                        data-test-id="resend-confirm-email-button"
                        disabled={this.state.success}
                        type="submit"
                    >
                        {intl.formatMessage(messages.button)}
                    </button>
                </form>
            </section>
        );
    }
}

export default injectIntl(ResendConfirmEmail);
