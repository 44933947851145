import autobind from 'common/decorators/autobind.js';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {sendResetPasswordEmail} from 'accounts/actions/accountsActions.js';
import FormNotice from 'core/components/FormNotice.js';
import MenuBar from 'core/components/MenuBar.js';
import SingleInput from 'core/components/SingleInput.js';

const messages = defineMessages({
    emailLabel: {
        id: 'accounts.ResetPassword.emailLabel',
        defaultMessage: 'emailLabel',
    },
    emailPlaceholder: {
        id: 'accounts.ResetPassword.emailPlaceholder',
        defaultMessage: 'emailPlaceholder',
    },
    resetPasswordButton: {
        id: 'accounts.ResetPassword.resetPasswordButton',
        defaultMessage: 'resetPasswordButton',
    },
    resetPasswordSubtitle: {
        id: 'accounts.ResetPassword.resetPasswordSubtitle',
        defaultMessage: 'resetPasswordSubtitle',
    },
    resetPasswordTitle: {
        id: 'accounts.ResetPassword.resetPasswordTitle',
        defaultMessage: 'resetPasswordTitle',
    },
    successMessage: {
        id: 'accounts.ResetPassword.successMessage',
        defaultMessage: 'successMessage',
    },
});

export class ResetPassword extends React.Component {
    constructor() {
        super();

        this.state = {
            email: '',
        };
    }

    componentDidMount() {
        if (this.props.loggedIn) {
            window.location.assign('/');
        }
    }

    @autobind
    updateEmail(value) {
        this.setState({email: value});
    }

    @autobind
    sendResetPasswordEmail(event) {
        event.preventDefault();
        this.props.sendResetPasswordEmail(this.state.email);
    }

    render() {
        const intl = this.props.intl;
        return (
            <section className="view">
                <MenuBar menuType="accounts" />
                <form onSubmit={this.sendResetPasswordEmail}>
                    <header className="title">
                        <h1 data-test-id="reset-password-title">
                            {intl.formatMessage(messages.resetPasswordTitle)}
                        </h1>
                        <p data-test-id="reset-password-subtitle">
                            {intl.formatMessage(
                                messages.resetPasswordSubtitle,
                            )}
                        </p>
                    </header>
                    {this.props.success && (
                        <FormNotice
                            errorType="success"
                            message={intl.formatMessage(
                                messages.successMessage,
                            )}
                        />
                    )}
                    <SingleInput
                        id="email-address"
                        label={intl.formatMessage(messages.emailLabel)}
                        name="email-address"
                        onChange={this.updateEmail}
                        placeholder={intl.formatMessage(
                            messages.emailPlaceholder,
                        )}
                        readOnly={this.props.success}
                        required={true}
                        type="email"
                    />
                    <button
                        className="cta"
                        data-test-id="reset-password-button"
                        disabled={this.props.success}
                        type="submit"
                    >
                        {intl.formatMessage(messages.resetPasswordButton)}
                    </button>
                </form>
            </section>
        );
    }
}

function mapStateToProps(store) {
    return {
        loggedIn: store.accounts.user.loggedIn,
        success: store.accounts.resetPassword.emailSuccess,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sendResetPasswordEmail: (email) =>
            dispatch(sendResetPasswordEmail(email)),
    };
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ResetPassword),
);
