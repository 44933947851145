import http from 'http.js';

import {
    BREATHE_API,
    CONSULTATIONS_CONSULTATIONS_API,
    CONSULTATIONS_CONSULTATIONS_API_V2,
    ACCOUNTS_HEALTHFUNDS_API,
    ACCOUNTS_LANGUAGE_API,
    ACCOUNTS_LOGIN_API,
    ACCOUNTS_LOGIN_GUEST_API,
    ACCOUNTS_LOGOUT_API,
    ACCOUNTS_PATIENTS_API,
    ACCOUNTS_PRACTICE_USER_API,
    ACCOUNTS_PRACTICES_API,
    ACCOUNTS_PRACTITIONERS_API,
    ACCOUNTS_RESET_PASSWORD,
    ACCOUNTS_USER_INFO_API,
    QUESTIONS_API,
} from 'apiEndpoints';
import batchActions from 'better_consult/actions/batchActions.js';
import actions from 'better_consult/actions/types.js';
import {getPatchData} from 'better_consult/actions/utils.js';
import {checkConsultationToken} from 'consultations/actions/consultationActions.js';
import {clearData} from 'dashboard/actions/dashboardActions.js';
import {findSelectedResult} from 'dashboard/helpers.js';
import {fetchMessages} from 'messages.js';
import {
    camelToSnake,
    camelToSnakeObjectKeys,
    snakeToCamelObjectKeys,
} from 'utils/case_converter.js';

// Action Creators
export async function getConsultations({
    practiceId,
    practitionerId,
    sort,
    completedAfter,
    bookingAfter,
}) {
    const data = {
        practice: practiceId,
        practitioner: practitionerId,
        sort: camelToSnake(sort),
        completedAfter,
        bookingAfter,
    };
    const response = await http.get({
        url: CONSULTATIONS_CONSULTATIONS_API_V2,
        data: camelToSnakeObjectKeys(data),
    });
    return response.map((item) => snakeToCamelObjectKeys(item));
}

export async function getConsultationsByConsultationId(consultationId) {
    const data = {consultation: consultationId};
    const response = await http.get({
        url: CONSULTATIONS_CONSULTATIONS_API,
        data,
    });
    return response.map((item) => snakeToCamelObjectKeys(item));
}

export async function getConsultationById(cid, source) {
    let response;
    const queryString = source ? `?source=${source}` : '';
    try {
        response = await http.get({
            url: `${CONSULTATIONS_CONSULTATIONS_API}${cid}/${queryString}`,
        });
    } catch (err) {
        const message = JSON.parse(err.message);
        return {
            error: true,
            status: message.status,
        };
    }
    return snakeToCamelObjectKeys(response);
}

export function getHealthFunds() {
    return async function (dispatch) {
        let response;
        try {
            response = await http.get({
                url: ACCOUNTS_HEALTHFUNDS_API,
            });
        } catch (err) {
            const message = JSON.parse(err.message);
            dispatch({
                type: actions.accounts.GET_HEALTH_FUNDS_FAILED,
                payload: JSON.parse(message.response),
            });
            return;
        }
        dispatch({
            type: actions.accounts.GET_HEALTH_FUNDS_SUCCESS,
            payload: response.map((item) => snakeToCamelObjectKeys(item)),
        });
    };
}

export function selectPatient(patientId) {
    return async function (dispatch, getState) {
        const {id} = getState().data.consultations.consultation;
        const url = `${CONSULTATIONS_CONSULTATIONS_API}${id}/`;
        const data = {
            patient: patientId,
        };
        await http.patch({url, data});
        dispatch({
            type: actions.data.SET_PATIENT_ATTR,
            payload: {field: 'id', value: patientId},
        });
    };
}

export function _oldCreatePatient(isDemo) {
    return async function (dispatch) {
        const url = ACCOUNTS_PATIENTS_API;
        const data = {
            // eslint-disable-next-line camelcase
            is_demo: isDemo,
        };
        const response = await http.post({url, data});
        dispatch({
            type: actions.accounts.CREATE_PATIENT_SUCCESS,
            payload: response,
        });
        return response.id;
    };
}

export function removePatient(patientId) {
    return async function (dispatch) {
        const deleteUrl = `${ACCOUNTS_PATIENTS_API}${patientId}/`;
        await http.delete({url: deleteUrl});
        dispatch(
            batchActions({
                type: actions.accounts.REMOVE_PATIENT_SUCCESS,
                payload: {
                    patientId,
                },
            }),
        );
    };
}

export function selectPractice(practiceId) {
    return {
        type: actions.accounts.SELECT_PRACTICE_SUCCESS,
        payload: practiceId,
    };
}

export async function getPatients(practiceId) {
    const url = `${ACCOUNTS_PRACTICES_API}${practiceId}/patients/`;
    try {
        const response = await http.get({url});
        return response.map((item) => snakeToCamelObjectKeys(item));
    } catch (err) {
        return [];
    }
}

export async function sendInvite(patientDetails, pId) {
    const url = ACCOUNTS_PRACTICES_API;
    let response;
    for (const key of Object.keys(patientDetails)) {
        if (!patientDetails[key] && patientDetails[key] !== false) {
            delete patientDetails[key];
        }
    }
    try {
        response = await http.post({
            url: `${url}${pId.toString()}/invitations/`,
            data: patientDetails,
        });
    } catch (err) {
        let errors = {};
        const message = JSON.parse(err.message);
        if (message.contentType === 'application/json') {
            errors = JSON.parse(message.response);
        }
        return {
            error: true,
            errors,
        };
    }
    return response;
}

export function sendResetPasswordEmail(email) {
    return async function (dispatch) {
        try {
            await http.post({
                url: ACCOUNTS_RESET_PASSWORD,
                data: {email},
            });
        } catch (err) {
            dispatch({
                type: actions.accounts.SEND_RESET_PASSWORD_EMAIL_FAILED,
            });
            return;
        }
        dispatch({
            type: actions.accounts.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
        });
    };
}

export function updatePracticeUserDashboard(uid, data) {
    return async function (dispatch) {
        let response;
        const url = `${ACCOUNTS_PRACTITIONERS_API}${uid}/`;
        try {
            if (data.language) {
                localStorage.setItem('language', data.language);
            }
            response = await http.patch({
                url,
                data: camelToSnakeObjectKeys(data),
            });
        } catch (err) {
            let errors = {};
            const message = JSON.parse(err.message);
            if (message.contentType === 'application/json') {
                errors = JSON.parse(message.response);
            }
            return {
                error: true,
                errors: snakeToCamelObjectKeys(errors),
            };
        }
        dispatch(updateUser(response));
        dispatch(clearData());
        return snakeToCamelObjectKeys(response);
    };
}

export function updateUser(data) {
    return async function (dispatch) {
        await dispatch({
            type: actions.accounts.UPDATE_USER_SUCCESS,
            payload: snakeToCamelObjectKeys(data),
        });
    };
}

export function updatePractitionerPassword(pId, data) {
    return async function (dispatch) {
        const url = `${ACCOUNTS_PRACTITIONERS_API}${pId}/`;
        try {
            await http.patch({url, data});
        } catch (err) {
            const message = JSON.parse(err.message);
            dispatch({
                type: actions.accounts.UPDATE_PASSWORD_SETTINGS_FAILED,
                payload: JSON.parse(message.response),
            });
            return;
        }
        dispatch({
            type: actions.accounts.UPDATE_PASSWORD_SETTINGS_SUCCESS,
        });
    };
}

export function loadUser(consultation) {
    return async function (dispatch) {
        let response;
        let data = null;
        if (consultation) {
            data = {consultation};
        }
        try {
            response = await http.get({
                url: ACCOUNTS_USER_INFO_API,
                data,
            });
        } catch (err) {
            dispatch({type: actions.accounts.GET_USER_FAILED});
            return false;
        }
        response = snakeToCamelObjectKeys(response);
        const {mustComplyWithHipaa} = response;
        delete response.mustComplyWithHipaa;
        await dispatch(
            batchActions(
                {
                    type: actions.accounts.GET_USER_SUCCESS,
                    payload: response,
                },
                {
                    type: actions.app.SET_MUST_COMPLY_WITH_HIPAA,
                    payload: mustComplyWithHipaa,
                },
            ),
        );
        return true;
    };
}

export function loginUser(user, token, nextLocation) {
    return async function (dispatch) {
        let response;
        try {
            const url = ACCOUNTS_LOGIN_API;
            const data = {
                email: user.email,
                password: user.password,
                remember: user.remember,
                token,

                // route to redirect to
                route: 'consultations',
            };

            response = snakeToCamelObjectKeys(await http.post({url, data}));
            nextLocation = nextLocation || response.defaultRedirectPath;
        } catch (err) {
            const message = JSON.parse(err.message);
            const errResponse = JSON.parse(message.response);
            nextLocation = errResponse.default_redirect_path;
            if (nextLocation) {
                window.location.assign(nextLocation);
                return;
            } else {
                const errors = {
                    email: errResponse.email,
                    password: errResponse.password,
                    nonFieldErrors: errResponse.non_field_errors || [],
                };
                return {errors};
            }
        }
        dispatch({
            type: actions.accounts.GET_USER_SUCCESS,
            payload: response,
        });
        if (nextLocation) {
            window.location.assign(nextLocation);
        }
    };
}

export function loginGuestUser(token) {
    return async function (dispatch) {
        const url = ACCOUNTS_LOGIN_GUEST_API;
        const data = {token};
        try {
            await http.post({url, data});
        } catch (err) {
            const message = JSON.parse(err.message);
            const errResponse = JSON.parse(message.response);
            throw new Error(errResponse.token);
        }
        await dispatch(loadUser());
        await dispatch(checkConsultationToken(token));
    };
}

export function logoutUser() {
    return async function (dispatch) {
        try {
            const url = ACCOUNTS_LOGOUT_API;
            await http.post({url});
        } catch (err) {
            throw err;
        }
        dispatch({type: actions.accounts.LOGOUT_USER_SUCCESS});
    };
}

export function setLanguage(preferredLanguage) {
    return async function (dispatch, getState) {
        let response;
        let language;
        try {
            const url = ACCOUNTS_LANGUAGE_API;
            const data = {
                language: preferredLanguage,
            };
            response = await http.post({url, data});
            language = response.language;
        } catch (err) {
            language = document.documentElement.getAttribute('lang');
        }
        let messages = getState().app.supportedMessages[preferredLanguage];
        if (!messages) {
            messages = await fetchMessagesAction();
        }
        localStorage.setItem('language', language);
        dispatch({
            type: actions.accounts.SET_LANGUAGE_SUCCESS,
            payload: {
                messages,
                languageCode: language,
            },
        });
    };
}

const MESSAGE_API_RETRY_TIMER = 3000;
const MESSAGE_API_MAX_RETRY = 3;
export async function fetchMessagesAction(timer = null) {
    // redirect to error page if the message API
    // fails to load for five times. Second call will be fired immediately
    // to make sure we get messages promptly after first failure,
    // then retry for three times every five seconds if still failed
    if (timer && timer / MESSAGE_API_RETRY_TIMER >= MESSAGE_API_MAX_RETRY) {
        window.location.assign('/error/');
    }
    let messages = null;
    try {
        messages = await fetchMessages();
    } catch (err) {
        timer = timer === null ? 0 : timer + MESSAGE_API_RETRY_TIMER;
        messages = await new Promise((resolve) => {
            setTimeout(async () => {
                resolve(await fetchMessagesAction(timer));
            }, timer);
        });
    }
    return messages;
}

export function createPatient(isDemo = false) {
    return async function (dispatch) {
        let response;
        try {
            const url = `${ACCOUNTS_PATIENTS_API}`;
            response = snakeToCamelObjectKeys(
                await http.post({
                    url,
                    // eslint-disable-next-line camelcase
                    data: {is_demo: isDemo},
                }),
            );
        } catch (err) {
            throw err;
        }
        dispatch({
            type: actions.data.ADD_PATIENT,
            payload: response,
        });
        return response;
    };
}

export function deletePatient() {
    return async function (dispatch, getState) {
        const id = getState().data.accounts.patient.id;
        try {
            const url = `${ACCOUNTS_PATIENTS_API}${id}/`;
            await http.delete({url});
        } catch (err) {
            throw err;
        }
        dispatch(
            batchActions({
                type: actions.data.REMOVE_PATIENT,
            }),
        );
    };
}

/**
 * This is a generic updater for non-flattened fields
 */
export function updatePatient({field, toReplace, toAdd, toRemove}) {
    return async function (dispatch, getState) {
        const {patient} = getState().data.accounts;
        const value = patient[field];
        const data = getPatchData({value, toReplace, toAdd, toRemove});
        let response;
        try {
            const url = `${ACCOUNTS_PATIENTS_API}${patient.id}/`;
            response = snakeToCamelObjectKeys(
                await http.patch({
                    // eslint-disable-next-line max-len
                    url,
                    data: {
                        [camelToSnake(field)]: data,
                    },
                }),
            );
        } catch (err) {
            throw err;
        }
        dispatch({
            type: actions.data.SET_PATIENT_ATTR,
            payload: {field, value: response[field]},
        });
    };
}

export function createPatientRecreationalDrugs(drugs) {
    return async function (dispatch, getState) {
        const {patient} = getState().data.accounts;
        let response;
        try {
            response = await http.post({
                url: `${ACCOUNTS_PATIENTS_API}${patient.id}/rec-drugs/`,
                // eslint-disable-next-line camelcase
                data: {rec_drugs: drugs},
            });
        } catch (err) {
            throw err;
        }
        dispatch({
            type: actions.data.ADD_PATIENT_RECREATIONAL_DRUGS,
            payload: response,
        });
    };
}

export function updatePatientRecreationalDrug(id, {field, toReplace}) {
    return async function (dispatch, getState) {
        const {patient} = getState().data.accounts;
        const value = getState().data.accounts.patientRecreationalDrugs[id][
            field
        ];
        const data = getPatchData({value, toReplace});
        let response;
        try {
            response = snakeToCamelObjectKeys(
                await http.patch({
                    url: `${ACCOUNTS_PATIENTS_API}${patient.id}/rec-drugs/${id}/`,
                    data: {
                        [camelToSnake(field)]: data,
                    },
                }),
            );
        } catch (err) {
            throw err;
        }
        dispatch({
            type: actions.data.SET_PATIENT_RECREATIONAL_DRUG_ATTR,
            payload: {id, field, value: response[field]},
        });
    };
}

export async function callTermsOfUseEndpoint(method, data, dispatch) {
    if (!['get', 'post'].includes(method)) {
        return;
    }

    let response;
    try {
        response = await http[method]({
            url: `${ACCOUNTS_PRACTICE_USER_API}terms-of-use-consent/`,
            data: camelToSnakeObjectKeys(data),
        });
    } catch (e) {
        try {
            const errorInfo = JSON.parse(e.message);
            if (errorInfo.status !== 404) {
                // Show error screen for non-404s
                dispatch({
                    type: actions.dashboard.SET_DASHBOARD_ERROR,
                    payload: 'BCD-001',
                });
                return;
            }
        } catch {
            // Invalid json - show error screen
            dispatch({
                type: actions.dashboard.SET_DASHBOARD_ERROR,
                payload: 'BCD-002',
            });
            return;
        }
    }

    const termsOfUse = snakeToCamelObjectKeys(response);
    return termsOfUse;
}

export function fetchTermsOfUse() {
    return async function (dispatch, getState) {
        const {SET_TERMS_OF_USE} = actions.accounts;

        const store = getState();
        const selectedPractice = findSelectedResult(
            store.accounts.user.practices,
        );
        const practiceUserToken = selectedPractice.token;

        const storedTermsOfUse = store.accounts.user.termsOfUse;

        if (!!storedTermsOfUse.fullscreenTerms) {
            // Dashboard is disabled and shows the terms of use screen
            // prompting the user to accept the terms before they can
            // have access
            return;
        }

        const termsOfUse = await callTermsOfUseEndpoint(
            'get',
            {
                practiceUserToken,
            },
            dispatch,
        );

        if (termsOfUse === undefined) {
            return;
        }

        dispatch({
            type: SET_TERMS_OF_USE,
            payload: termsOfUse,
        });
    };
}

export function recordTermsOfUseConsent(
    consentedTermsOfUse,
    redirectToConsultationsList = false,
) {
    return async function (dispatch, getState) {
        const {user} = getState().accounts;
        const {practices} = user;
        const {majorVersion, minorVersion} = consentedTermsOfUse;
        const selectedPractice = findSelectedResult(practices);
        const practiceUserToken = selectedPractice.token;

        const termsOfUse = await callTermsOfUseEndpoint(
            'post',
            {
                practiceUserToken,
                majorVersion,
                minorVersion,
            },
            dispatch,
        );

        if (termsOfUse === undefined) {
            return;
        }

        const {SET_TERMS_OF_USE} = actions.accounts;
        dispatch({
            type: SET_TERMS_OF_USE,
            payload: termsOfUse,
        });

        if (redirectToConsultationsList) {
            // Redirect to consultations list
            const consultationsListRedirectUrl = `/dashboard/consultations/`;
            window.location.assign(consultationsListRedirectUrl);
        }
    };
}

export async function getQuestionOutputGroups() {
    try {
        const response = await http.get({
            url: `${QUESTIONS_API}question_output_groups/`,
        });
        return snakeToCamelObjectKeys(response);
    } catch (e) {
        return [];
    }
}

export async function getCDSSAssessmentSummary(conId) {
    const data = {consultation_id: conId};
    try {
        const response = await http.get({
            url: `${BREATHE_API}assessment-summary/${conId}/`,
            data: data,
        });
        return snakeToCamelObjectKeys(response);
    } catch (e) {
        console.error('Error fetching CDSS Assessment Summary:', e);
        return null;
    }
}

export async function getBreatheQuestions(conId) {
    try {
        const response = await http.get({
            url: `${BREATHE_API}questions/${conId}/`,
        });
        return snakeToCamelObjectKeys(response);
    } catch (e) {
        console.error('Error fetching Breathe questions:', e);
        return null;
    }
}

export async function saveBreatheQuestionsResponses(conId, data) {
    try {
        const url = `${BREATHE_API}questions/${conId}/`;
        const response = await http.post({
            url,
            data: camelToSnakeObjectKeys(data),
        });
        return response;
    } catch (e) {
        console.error('Error posting Breathe questions:', e);
        return null;
    }
}
