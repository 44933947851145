import autobind from 'common/decorators/autobind.js';
import http from 'http.js';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import query from 'query.js';

import {loginUser} from 'accounts/actions/accountsActions.js';
import {ACCOUNTS_SET_PASSWORD_API} from 'apiEndpoints.js';
import FormNotice from 'core/components/FormNotice.js';
import MenuBar from 'core/components/MenuBar.js';
import SingleInput from 'core/components/SingleInput.js';

export const messages = defineMessages({
    errorMessage: {
        id: 'accounts.SetPassword.errorMessage',
        defaultMessage: 'errorMessage',
    },
    matchingPasswordError: {
        id: 'accounts.SetPassword.matchingPasswordError',
        defaultMessage: 'matchingPasswordError',
    },
    newPasswordLabel: {
        id: 'accounts.SetPassword.newPasswordLabel',
        defaultMessage: 'newPasswordLabel',
    },
    passwordConfirmLabel: {
        id: 'accounts.SetPassword.passwordConfirmLabel',
        defaultMessage: 'passwordConfirmLabel',
    },
    passwordPlaceholder: {
        id: 'accounts.SetPassword.passwordPlaceholder',
        defaultMessage: 'passwordPlaceholder',
    },
    setPasswordButton: {
        id: 'accounts.SetPassword.setPasswordButton',
        defaultMessage: 'setPasswordButton',
    },
    setPasswordSubtitle: {
        id: 'accounts.SetPassword.setPasswordSubtitle',
        defaultMessage: 'setPasswordSubtitle',
    },
    setPasswordTitle: {
        id: 'accounts.SetPassword.setPasswordTitle',
        defaultMessage: 'setPasswordTitle',
    },
    successMessage: {
        id: 'accounts.SetPassword.successMessage',
        defaultMessage: 'successMessage',
    },
});

export class SetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordConfirm: '',
            passwordErrors: [],
            token: '',
            success: false,
            error: false,
        };
        this.updatePassword = this.updateField.bind(this, 'password');
        this.updatePasswordConfirm = this.updateField.bind(
            this,
            'passwordConfirm',
        );
    }

    componentDidMount() {
        const queryData = query.parse();
        if (queryData) {
            this.setState({token: queryData.token});
        }
        if (this.props.loggedIn) {
            return window.location.assign('/');
        }
    }

    updateField(name, value) {
        this.setState({[name]: value});
    }

    @autobind
    async setPassword(event) {
        event.preventDefault();
        const intl = this.props.intl;
        let passwordErrors = [];
        let email;
        if (this.state.password === this.state.passwordConfirm) {
            try {
                const data = {
                    password: this.state.password,
                    token: this.state.token,
                };
                const resp = await http.post({
                    url: ACCOUNTS_SET_PASSWORD_API,
                    data,
                });
                email = resp.email;
                this.setState({
                    success: true,
                    passwordErrors,
                });
            } catch (err) {
                const message = JSON.parse(err.message);
                try {
                    const errors = JSON.parse(message.response);
                    if (Array.isArray(errors) && errors.length) {
                        passwordErrors = passwordErrors.concat(errors[0]);
                    } else if (typeof errors === 'string') {
                        passwordErrors = passwordErrors.concat(errors);
                    } else {
                        this.setState({error: true});
                    }
                } catch (error) {
                    this.setState({error: true});
                }
                this.setState({passwordErrors});
                return;
            }
            await this.props.loginUser({
                email,
                password: this.state.password,
                remember: false,
            });
        } else {
            passwordErrors = [
                intl.formatMessage(messages.matchingPasswordError),
            ];
            this.setState({passwordErrors});
        }
    }

    renderNotice() {
        const {error, passwordErrors, success} = this.state;
        const {intl} = this.props;
        let noticeProps;

        if (success) {
            noticeProps = {
                errorType: 'success',
                message: intl.formatMessage(messages.successMessage),
            };
        } else if (error) {
            noticeProps = {
                errorType: 'error',
                message: intl.formatMessage(messages.errorMessage),
            };
        } else if (passwordErrors.length) {
            // passwordErrors should not contain more than one string.
            // TODO #27696 - change this from an array to a string.
            noticeProps = {
                errorType: 'error',
                message: passwordErrors[0],
            };
        } else {
            return null;
        }

        return <FormNotice {...noticeProps} />;
    }

    render() {
        const intl = this.props.intl;
        return (
            <section className="view">
                <MenuBar menuType="accounts" />
                <form onSubmit={this.setPassword}>
                    <header className="title">
                        <h1 data-test-id="set-password-title">
                            {intl.formatMessage(messages.setPasswordTitle)}
                        </h1>
                        <p data-test-id="set-password-subtitle">
                            {intl.formatMessage(messages.setPasswordSubtitle)}
                        </p>
                    </header>
                    {this.renderNotice()}
                    <SingleInput
                        id="password"
                        label={intl.formatMessage(messages.newPasswordLabel)}
                        name="password"
                        onChange={this.updatePassword}
                        placeholder={intl.formatMessage(
                            messages.passwordPlaceholder,
                        )}
                        readOnly={this.state.success}
                        required={true}
                        reveal={true}
                        type="password"
                    />
                    <SingleInput
                        id="password-confirm"
                        label={intl.formatMessage(
                            messages.passwordConfirmLabel,
                        )}
                        name="password-confirm"
                        onChange={this.updatePasswordConfirm}
                        placeholder={intl.formatMessage(
                            messages.passwordPlaceholder,
                        )}
                        readOnly={this.state.success}
                        required={true}
                        reveal={true}
                        type="password"
                    />
                    <button
                        className="cta"
                        data-test-id="set-password-button"
                        disabled={this.state.success}
                        type="submit"
                    >
                        {intl.formatMessage(messages.setPasswordButton)}
                    </button>
                </form>
            </section>
        );
    }
}

function mapStateToProps(store) {
    return {
        loggedIn: store.accounts.user.loggedIn,
        token: store.data.consultations.consultation.token,
    };
}

function mapDispatchToProps(dispatch) {
    return {loginUser: (user) => dispatch(loginUser(user))};
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(SetPassword),
);
